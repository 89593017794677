#style_switcher {
	position: fixed;
	right: 0;
	top: 140px;
	background: #fff;
	width: 240px;
	z-index: 1094;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 16px;
	margin-right: -246px;
	-webkit-transition: margin 280ms cubic-bezier(.4,0,.2,1);
	transition: margin 280ms cubic-bezier(.4,0,.2,1);
	-webkit-box-shadow: -2px 2px 5px rgba(0,0,0,.26);
	box-shadow: -2px 2px 5px rgba(0,0,0,.26);
	.switcher_app_themes {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		>li {
			padding: 0;
			margin: 0;
			list-style: none;
		}
		li {
			float: left;
			cursor: pointer;
			width: 36px;
			margin: 0 16px 16px 0;
			opacity: .5;
			-webkit-transition: opacity 280ms cubic-bezier(.4,0,.2,1);
			transition: opacity 280ms cubic-bezier(.4,0,.2,1);
			span {
				display: block;
				padding: 0 4px;
				height: 24px;
				line-height: 24px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				width: 50%;
				float: left;
			}
		}
		li.active_theme {
			opacity: 1;
			position: relative;
			&:after {
				position: absolute;
				top: 0;
				left: 50%;
				content: '\e5ca';
				font-family: "Material Icons";
				font-size: 18px;
				display: block;
				color: #fff;
				margin-left: -8px;
			}
		}
		li.app_style_default {
			.app_color_main {
				background: #2196f3;
			}
			.app_color_accent {
				background: #7cb342;
			}
		}
		li.switcher_theme_a {
			.app_color_main {
				background: #3f51b5;
			}
			.app_color_accent {
				background: #ff4081;
			}
		}
		li.switcher_theme_b {
			.app_color_main {
				background: #673ab7;
			}
			.app_color_accent {
				background: #00bcd4;
			}
		}
		li.switcher_theme_c {
			.app_color_main {
				background: #5d4037;
			}
			.app_color_accent {
				background: #1e88e5;
			}
		}
		li.switcher_theme_d {
			.app_color_main {
				background: #00acc1;
			}
			.app_color_accent {
				background: #673ab7;
			}
		}
		li.switcher_theme_e {
			.app_color_main {
				background: #607d8b;
			}
			.app_color_accent {
				background: #e53935;
			}
		}
		li.switcher_theme_f {
			.app_color_main {
				background: #424242;
			}
			.app_color_accent {
				background: #7cb342;
			}
		}
		li.switcher_theme_g {
			.app_color_main {
				background: #8e24aa;
			}
			.app_color_accent {
				background: #4caf50;
			}
		}
		li.switcher_theme_h {
			.app_color_main {
				background: #d32f2f;
			}
			.app_color_accent {
				background: #0277bd;
			}
		}
		li.switcher_theme_i {
			.app_color_main {
				background: #fbc02d;
			}
			.app_color_accent {
				background: #00acc1;
			}
		}
		li.switcher_theme_dark {
			.app_color_main {
				background: #546e7a;
			}
			.app_color_accent {
				background: #009688;
			}
		}
	}
}
#style_switcher.switcher_active {
	margin-right: 0;
}
#style_switcher_toggle {
	position: absolute;
	top: 8px;
	left: -44px;
	width: 44px;
	height: 36px;
	background: #fff;
	border-radius: 4px 0 0 4px;
	cursor: pointer;
	padding: 0 8px;
	-webkit-box-shadow: -2px 2px 5px rgba(0,0,0,.26);
	box-shadow: -2px 2px 5px rgba(0,0,0,.26);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 1096;
	.material-icons {
		font-size: 24px;
		line-height: 36px;
		color: #212121;
	}
}
.app_theme_dark {
	#style_switcher {
		background: #424242;
	}
	#style_switcher_toggle {
		background: #424242;
		.material-icons {
			color: #cbcbcb;
		}
	}
}
