.app_theme_a {
	.uk-alert {
		background: #3f51b5;
	}
	.md-btn-primary {
		background: #3f51b5;
		color: #fff;
		&:active {
			background: #3f51b5;
			color: #fff;
		}
		&:focus {
			background: #3f51b5;
			color: #fff;
		}
		&:hover {
			background: #3f51b5;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #303f9f;
		&:active {
			color: #303f9f;
			background: #d1d6ef;
		}
		&:focus {
			color: #303f9f;
			background: #d1d6ef;
		}
		&:hover {
			color: #303f9f;
			background: #d1d6ef;
		}
	}
	.uk-badge-primary {
		background: #3f51b5;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #e8eaf6;
					}
				}
			}
			.md-card-list-item-selected {
				background: #e8eaf6;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #e8eaf6;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #ff4081;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #ff4081;
			}
		}
		>li.md-list-item-active {
			color: #ff4081;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #ff4081;
				.material-icons {
					color: #ff4081;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #ffd9e6;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #ff4081;
				color: #fff;
			}
			>span {
				background: #ff4081;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #ff4081;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #abb4e2;
				}
				&:hover {
					border-bottom-color: #abb4e2;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #3f51b5;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #abb4e2;
				}
				&:hover {
					border-top-color: #abb4e2;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #3f51b5;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #abb4e2;
				}
				&:hover {
					border-right-color: #abb4e2;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #3f51b5;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #abb4e2;
				}
				&:hover {
					border-left-color: #abb4e2;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #3f51b5;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #606fc7;
				}
				&:hover {
					border-bottom-color: #606fc7;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #ff4081;
			}
		}
	}
	#header_main {
		background: #3f51b5;
	}
	.header_double_height {
		&:after {
			background: #3f51b5;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #3f51b5;
						box-shadow: inset 0 -3px 0 #3f51b5;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #3f51b5;
					box-shadow: inset 0 -3px 0 #3f51b5;
				}
			}
		}
	}
	.user_heading {
		background: #303f9f;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #ff4081;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #ff4081;
							}
						}
						.menu_title {
							color: #ff4081;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #ff4081;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #ff4081;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #ff4081;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #ff4081;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #ff4081;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #ff4081;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #ff4081;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #3f51b5,inset 0 -1px 0 2px #3f51b5;
			box-shadow: inset 0 0 0 2px #3f51b5,inset 0 -1px 0 2px #3f51b5;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #e8eaf6;
				}
			}
		}
		.headerSortDown {
			color: #ff4081;
		}
		.headerSortUp {
			color: #ff4081;
		}
		.tablesorter-headerAsc {
			color: #ff4081;
		}
		.tablesorter-headerDesc {
			color: #ff4081;
		}
		.tablesorter-headerSortDown {
			color: #ff4081;
		}
		.tablesorter-headerSortUp {
			color: #ff4081;
		}
	}
	.wizard {
		>.steps {
			background: #3f51b5;
			>ul {
				>li.current {
					a {
						background: #606fc7;
						&:active {
							background: #606fc7;
						}
						&:hover {
							background: #606fc7;
						}
					}
				}
				>li.done {
					a {
						background: #303f9f;
						&:active {
							background: #303f9f;
						}
						&:hover {
							background: #303f9f;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #3f51b5;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(63,81,181,.4);
		}
	}
	.search_list_link {
		color: #ff4081;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #303f9f;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #3f51b5;
					}
				}
			}
		}
	}
}
.app_theme_b {
	.uk-alert {
		background: #673ab7;
	}
	.md-btn-primary {
		background: #673ab7;
		color: #fff;
		&:active {
			background: #673ab7;
			color: #fff;
		}
		&:focus {
			background: #673ab7;
			color: #fff;
		}
		&:hover {
			background: #673ab7;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #512da8;
		&:active {
			color: #512da8;
			background: #daceef;
		}
		&:focus {
			color: #512da8;
			background: #daceef;
		}
		&:hover {
			color: #512da8;
			background: #daceef;
		}
	}
	.uk-badge-primary {
		background: #673ab7;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #ede7f6;
					}
				}
			}
			.md-card-list-item-selected {
				background: #ede7f6;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #ede7f6;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #00bcd4;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #00bcd4;
			}
		}
		>li.md-list-item-active {
			color: #00bcd4;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #00bcd4;
				.material-icons {
					color: #00bcd4;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #d4faff;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #00bcd4;
				color: #fff;
			}
			>span {
				background: #00bcd4;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #00bcd4;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #bda7e3;
				}
				&:hover {
					border-bottom-color: #bda7e3;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #673ab7;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #bda7e3;
				}
				&:hover {
					border-top-color: #bda7e3;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #673ab7;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #bda7e3;
				}
				&:hover {
					border-right-color: #bda7e3;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #673ab7;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #bda7e3;
				}
				&:hover {
					border-left-color: #bda7e3;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #673ab7;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #8259cb;
				}
				&:hover {
					border-bottom-color: #8259cb;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #00bcd4;
			}
		}
	}
	#header_main {
		background: #673ab7;
	}
	.header_double_height {
		&:after {
			background: #673ab7;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #673ab7;
						box-shadow: inset 0 -3px 0 #673ab7;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #673ab7;
					box-shadow: inset 0 -3px 0 #673ab7;
				}
			}
		}
	}
	.user_heading {
		background: #512da8;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #00bcd4;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #00bcd4;
							}
						}
						.menu_title {
							color: #00bcd4;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #00bcd4;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #00bcd4;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #00bcd4;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #00bcd4;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #00bcd4;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #00bcd4;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #00bcd4;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #673ab7,inset 0 -1px 0 2px #673ab7;
			box-shadow: inset 0 0 0 2px #673ab7,inset 0 -1px 0 2px #673ab7;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #ede7f6;
				}
			}
		}
		.headerSortDown {
			color: #00bcd4;
		}
		.headerSortUp {
			color: #00bcd4;
		}
		.tablesorter-headerAsc {
			color: #00bcd4;
		}
		.tablesorter-headerDesc {
			color: #00bcd4;
		}
		.tablesorter-headerSortDown {
			color: #00bcd4;
		}
		.tablesorter-headerSortUp {
			color: #00bcd4;
		}
	}
	.wizard {
		>.steps {
			background: #673ab7;
			>ul {
				>li.current {
					a {
						background: #8259cb;
						&:active {
							background: #8259cb;
						}
						&:hover {
							background: #8259cb;
						}
					}
				}
				>li.done {
					a {
						background: #512da8;
						&:active {
							background: #512da8;
						}
						&:hover {
							background: #512da8;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #673ab7;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(103,58,183,.4);
		}
	}
	.search_list_link {
		color: #00bcd4;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #512da8;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #673ab7;
					}
				}
			}
		}
	}
}
.app_theme_c {
	.uk-alert {
		background: #5d4037;
	}
	.md-btn-primary {
		background: #5d4037;
		color: #fff;
		&:active {
			background: #5d4037;
			color: #fff;
		}
		&:focus {
			background: #5d4037;
			color: #fff;
		}
		&:hover {
			background: #5d4037;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #3e2723;
		&:active {
			color: #3e2723;
			background: #c4a59c;
		}
		&:focus {
			color: #3e2723;
			background: #c4a59c;
		}
		&:hover {
			color: #3e2723;
			background: #c4a59c;
		}
	}
	.uk-badge-primary {
		background: #5d4037;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #efebe9;
					}
				}
			}
			.md-card-list-item-selected {
				background: #efebe9;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #efebe9;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #1e88e5;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #1e88e5;
			}
		}
		>li.md-list-item-active {
			color: #1e88e5;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #1e88e5;
				.material-icons {
					color: #1e88e5;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #d5e9fa;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #1e88e5;
				color: #fff;
			}
			>span {
				background: #1e88e5;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #1e88e5;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #b1887c;
				}
				&:hover {
					border-bottom-color: #b1887c;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #5d4037;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #b1887c;
				}
				&:hover {
					border-top-color: #b1887c;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #5d4037;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #b1887c;
				}
				&:hover {
					border-right-color: #b1887c;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #5d4037;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #b1887c;
				}
				&:hover {
					border-left-color: #b1887c;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #5d4037;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #7d564a;
				}
				&:hover {
					border-bottom-color: #7d564a;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #1e88e5;
			}
		}
	}
	#header_main {
		background: #5d4037;
	}
	.header_double_height {
		&:after {
			background: #5d4037;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #5d4037;
						box-shadow: inset 0 -3px 0 #5d4037;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #5d4037;
					box-shadow: inset 0 -3px 0 #5d4037;
				}
			}
		}
	}
	.user_heading {
		background: #3e2723;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #1e88e5;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #1e88e5;
							}
						}
						.menu_title {
							color: #1e88e5;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #1e88e5;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #1e88e5;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #1e88e5;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #1e88e5;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #1e88e5;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #1e88e5;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #1e88e5;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #5d4037,inset 0 -1px 0 2px #5d4037;
			box-shadow: inset 0 0 0 2px #5d4037,inset 0 -1px 0 2px #5d4037;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #efebe9;
				}
			}
		}
		.headerSortDown {
			color: #1e88e5;
		}
		.headerSortUp {
			color: #1e88e5;
		}
		.tablesorter-headerAsc {
			color: #1e88e5;
		}
		.tablesorter-headerDesc {
			color: #1e88e5;
		}
		.tablesorter-headerSortDown {
			color: #1e88e5;
		}
		.tablesorter-headerSortUp {
			color: #1e88e5;
		}
	}
	.wizard {
		>.steps {
			background: #5d4037;
			>ul {
				>li.current {
					a {
						background: #7d564a;
						&:active {
							background: #7d564a;
						}
						&:hover {
							background: #7d564a;
						}
					}
				}
				>li.done {
					a {
						background: #3e2723;
						&:active {
							background: #3e2723;
						}
						&:hover {
							background: #3e2723;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #5d4037;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(93,64,55,.4);
		}
	}
	.search_list_link {
		color: #1e88e5;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #3e2723;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #5d4037;
					}
				}
			}
		}
	}
}
.app_theme_d {
	.uk-alert {
		background: #00acc1;
	}
	.md-btn-primary {
		background: #00acc1;
		color: #fff;
		&:active {
			background: #00acc1;
			color: #fff;
		}
		&:focus {
			background: #00acc1;
			color: #fff;
		}
		&:hover {
			background: #00acc1;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #00838f;
		&:active {
			color: #00838f;
			background: #c1f8ff;
		}
		&:focus {
			color: #00838f;
			background: #c1f8ff;
		}
		&:hover {
			color: #00838f;
			background: #c1f8ff;
		}
	}
	.uk-badge-primary {
		background: #00acc1;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #e0f7fa;
					}
				}
			}
			.md-card-list-item-selected {
				background: #e0f7fa;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #e0f7fa;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #673ab7;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #673ab7;
			}
		}
		>li.md-list-item-active {
			color: #673ab7;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #673ab7;
				.material-icons {
					color: #673ab7;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #ebe5f7;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #673ab7;
				color: #fff;
			}
			>span {
				background: #673ab7;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #673ab7;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #5bedff;
				}
				&:hover {
					border-bottom-color: #5bedff;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #00acc1;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #5bedff;
				}
				&:hover {
					border-top-color: #5bedff;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #00acc1;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #5bedff;
				}
				&:hover {
					border-right-color: #5bedff;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #00acc1;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #5bedff;
				}
				&:hover {
					border-left-color: #5bedff;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #00acc1;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #00c3db;
				}
				&:hover {
					border-bottom-color: #00c3db;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #673ab7;
			}
		}
	}
	#header_main {
		background: #00acc1;
	}
	.header_double_height {
		&:after {
			background: #00acc1;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #00acc1;
						box-shadow: inset 0 -3px 0 #00acc1;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #00acc1;
					box-shadow: inset 0 -3px 0 #00acc1;
				}
			}
		}
	}
	.user_heading {
		background: #00838f;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #673ab7;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #673ab7;
							}
						}
						.menu_title {
							color: #673ab7;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #673ab7;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #673ab7;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #673ab7;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #673ab7;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #673ab7;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #673ab7;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #673ab7;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #00acc1,inset 0 -1px 0 2px #00acc1;
			box-shadow: inset 0 0 0 2px #00acc1,inset 0 -1px 0 2px #00acc1;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #e0f7fa;
				}
			}
		}
		.headerSortDown {
			color: #673ab7;
		}
		.headerSortUp {
			color: #673ab7;
		}
		.tablesorter-headerAsc {
			color: #673ab7;
		}
		.tablesorter-headerDesc {
			color: #673ab7;
		}
		.tablesorter-headerSortDown {
			color: #673ab7;
		}
		.tablesorter-headerSortUp {
			color: #673ab7;
		}
	}
	.wizard {
		>.steps {
			background: #00acc1;
			>ul {
				>li.current {
					a {
						background: #00d9f4;
						&:active {
							background: #00d9f4;
						}
						&:hover {
							background: #00d9f4;
						}
					}
				}
				>li.done {
					a {
						background: #00838f;
						&:active {
							background: #00838f;
						}
						&:hover {
							background: #00838f;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #00acc1;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(0,172,193,.4);
		}
	}
	.search_list_link {
		color: #673ab7;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #00838f;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #00acc1;
					}
				}
			}
		}
	}
}
.app_theme_dark {
	background: #303030;
	color: #fefefe;
	a {
		color: #039be5;
		&:active {
			color: #0277bd;
		}
		&:hover {
			color: #0277bd;
		}
	}
	#breadcrumbs {
		background: #424242;
	}
	#footer {
		background: #424242;
	}
	#page_heading {
		background: #424242;
	}
	#sidebar_main {
		background: #424242;
		.sidebar_main_header {
			background-image: url(../../images/themes/altair/sidebar_head_bg_dark.png);
			background-repeat: no-repeat;
			background-position: 0 0;
			.selectize-control.single {
				.selectize-input {
					&:after {
						color: rgba(255,255,255,.25);
					}
				}
			}
			.sidebar_logo {
				a {
					.logo_light {
						display: inline-block;
					}
					.logo_regular {
						display: none;
					}
				}
			}
		}
		.menu_section {
			>ul {
				>li {
					a {
						color: #fefefe;
					}
					ul {
						li.act_item {
							a {
								color: #009688;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #009688;
							}
						}
						.menu_title {
							color: #009688;
						}
					}
				}
			}
		}
	}
	#sidebar_secondary {
		background: #424242;
		.uk-tab {
			background: #424242;
		}
	}
	.clndr {
		.clndr_events {
			background: #424242;
			.clndr_event {
				>a {
					color: #fefefe;
				}
			}
		}
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #009688;
					}
				}
				.day.last-month {
					color: #212121;
				}
				.day.next-month {
					color: #212121;
				}
				.day.day.event {
					>span {
						border-color: rgba(255,255,255,.25);
					}
				}
			}
		}
	}
	.gallery_grid_image_caption {
		background: #424242;
	}
	.map_search_wrapper {
		.map_search_list_wrapper {
			background: #424242;
		}
	}
	.md-card {
		background: #424242;
		.md-card-toolbar {
			background: #424242;
		}
		.md-card-head-text {
			color: #fefefe;
		}
		.md-card-toolbar-heading-text {
			color: #fefefe;
		}
		.md-card-toolbar-input {
			color: #fefefe;
		}
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li {
					background: #424242;
				}
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #546e7a;
					}
				}
			}
			.md-card-list-item-selected {
				background: #546e7a;
			}
			.md-card-list-item-selected.item-shown {
				background: #424242;
				&:before {
					background: #546e7a;
				}
			}
		}
	}
	.md-card.md-card-overlay {
		.md-card-overlay-content {
			background: #424242;
		}
	}
	.uk-accordion-alt {
		.uk-accordion-title {
			background: #424242;
		}
	}
	.uk-dropdown {
		background: #424242;
		color: #fefefe;
		>ul {
			>li {
				>a {
					color: #fefefe;
				}
			}
		}
	}
	.uk-nestable-panel {
		background: #424242;
	}
	.uk-sticky-placeholder {
		.uk-tab {
			background: #424242;
		}
	}
	.fc {
		.fc-day-grid-event {
			color: #fefefe;
		}
	}
	.gallery_grid_item {
		color: #fefefe;
		&:focus {
			color: #fefefe;
		}
		&:hover {
			color: #fefefe;
		}
	}
	.uk-article-lead {
		color: #fefefe;
	}
	.uk-badge {
		color: #fefefe;
	}
	.uk-nav-dropdown {
		>li {
			>a {
				color: #fefefe;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				color: #fefefe;
				&:focus {
					border-bottom-color: #a6b9c1;
				}
				&:hover {
					border-bottom-color: #a6b9c1;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #546e7a;
			}
		}
	}
	h1 {
		color: #fefefe;
	}
	h2 {
		color: #fefefe;
	}
	h3 {
		color: #fefefe;
	}
	h4 {
		color: #fefefe;
	}
	h5 {
		color: #fefefe;
	}
	h6 {
		color: #fefefe;
	}
	#menu_top {
		>li {
			>a {
				color: #cbcbcb;
			}
		}
	}
	.disabled+.inline-label {
		color: #cbcbcb;
	}
	.uk-datepicker-next {
		color: #cbcbcb;
	}
	.uk-datepicker-previous {
		color: #cbcbcb;
	}
	[class*=uk-icon-] {
		color: #cbcbcb;
	}
	.uk-text-muted {
		color: #999 !important;
	}
	.material-icons {
		color: rgba(255,255,255,.8);
	}
	.md-list-addon-element {
		>.md-list-addon-icon {
			color: rgba(255,255,255,.7);
		}
	}
	.md-input-wrapper {
		>label {
			color: #cbcbcb;
		}
	}
	.md-list {
		.uk-nestable-list {
			>li {
				>a.md-list-content {
					color: #cbcbcb;
				}
				border-color: #424242;
			}
			>li.md-list-item-active {
				color: #009688;
				background: #212121;
			}
		}
		>li {
			>a.md-list-content {
				color: #cbcbcb;
			}
			border-color: #616161;
		}
		>li.md-list-item-active {
			color: #009688;
			background: #212121;
		}
	}
	.md-list-outside {
		>li {
			>.md-list-content {
				color: #cbcbcb;
			}
		}
	}
	.uk-table {
		thead {
			th {
				color: #cbcbcb;
				border-color: #616161;
			}
		}
		td {
			border-color: #616161;
		}
		tfoot {
			td {
				border-color: #616161;
			}
			th {
				border-color: #616161;
			}
		}
		tr.row_checked {
			td {
				background: #212121;
			}
		}
	}
	input {
		&:not([type]).md-input {
			border-color: rgba(255,255,255,.25);
			color: #fefefe;
		}
	}
	input[type=color].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=date].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=datetime-local].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=datetime].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=email].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=month].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=number].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=password].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=search].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=tel].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=text].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=time].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=url].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	input[type=week].md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	select.md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	textarea.md-input {
		border-color: rgba(255,255,255,.25);
		color: #fefefe;
	}
	select {
		optgroup {
			background: #424242;
		}
		option {
			background: #424242;
		}
		&:disabled {
			color: #aaa;
		}
	}
	.md-hr {
		border-top-color: rgba(0,0,0,.15);
	}
	button {
		&:disabled {
			color: #aaa;
		}
	}
	input[type=button] {
		&:disabled {
			color: #aaa;
		}
	}
	input[type=file] {
		&:disabled {
			&::-webkit-file-upload-button {
				color: #aaa;
			}
		}
	}
	input[type=reset] {
		&:disabled {
			color: #aaa;
		}
	}
	input[type=submit] {
		&:disabled {
			color: #aaa;
		}
	}
	keygen {
		&:disabled {
			color: #aaa;
		}
	}
	optgroup {
		&:disabled {
			color: #aaa;
		}
	}
	option {
		&:disabled {
			color: #aaa;
		}
	}
	select[disabled] {
		>option {
			color: #aaa;
		}
	}
	.uk-tooltip {
		background: #212121;
	}
	.uk-thumbnail {
		background: #616161;
	}
	.uk-thumbnail-caption {
		color: #fefefe;
	}
	.uk-alert {
		background: #546e7a;
	}
	.md-btn {
		color: #212121;
	}
	.md-btn.disabled {
		background: #616161;
	}
	.md-btn-primary {
		background: #546e7a;
		color: #fff;
		&:active {
			background: #546e7a;
			color: #fff;
		}
		&:focus {
			background: #546e7a;
			color: #fff;
		}
		&:hover {
			background: #546e7a;
			color: #fff;
		}
	}
	.md-btn-flat {
		color: #fefefe;
		&:active {
			color: #fefefe;
		}
		&:focus {
			color: #fefefe;
		}
		&:hover {
			color: #fefefe;
		}
	}
	.md-btn-flat[data-uk-button] {
		&:active {
			color: #727272;
		}
		&:focus {
			color: #727272;
		}
		&:hover {
			color: #727272;
		}
	}
	.md-btn-flat-primary.uk-active {
		color: #fefefe !important;
		background: #546e7a;
	}
	.md-btn-flat-warning.uk-active {
		color: #fefefe !important;
		background: #ffa000;
	}
	.md-btn-flat-danger.uk-active {
		color: #fefefe !important;
		background: #e53935;
	}
	.md-btn-flat-success.uk-active {
		color: #fefefe !important;
		background: #7cb342;
	}
	.md-btn-flat.disabled {
		color: #616161;
	}
	.uk-badge-primary {
		background: #546e7a;
	}
	.uk-datepicker-nav {
		background: #009688;
	}
	.uk-datepicker-table {
		a {
			color: #fefefe;
		}
	}
	a.uk-datepicker-table-muted {
		color: #212121;
	}
	.md-fab.md-fab-accent {
		background: #009688;
	}
	.md-fab {
		.material-icons {
			color: #727272;
		}
	}
	.md-fab-accent {
		.material-icons {
			color: #fefefe;
		}
	}
	.md-fab-danger {
		.material-icons {
			color: #fefefe;
		}
	}
	.md-fab-primary {
		.material-icons {
			color: #fefefe;
		}
	}
	.md-fab-success {
		.material-icons {
			color: #fefefe;
		}
	}
	.md-fab-warning {
		.material-icons {
			color: #fefefe;
		}
	}
	.uk-file-upload {
		background: #616161;
	}
	.iradio_md {
		border-color: rgba(255,255,255,.4);
	}
	.icheckbox_md {
		border-color: rgba(255,255,255,.4);
		background: #424242;
	}
	.icheckbox_md.checked {
		background: #009688;
		border-color: #009688;
	}
	.icheckbox_md.hover.checked {
		background: #009688;
		border-color: #009688;
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #009688;
				.material-icons {
					color: #009688;
				}
			}
		}
	}
	.uk-modal-dialog {
		background: #424242;
		.uk-modal-footer {
			background: #424242;
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #30ffec;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #009688;
				color: #fff;
			}
			>span {
				background: #009688;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #009688;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #a6b9c1;
				}
				&:hover {
					border-top-color: #a6b9c1;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #546e7a;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #a6b9c1;
				}
				&:hover {
					border-right-color: #a6b9c1;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #546e7a;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #a6b9c1;
				}
				&:hover {
					border-left-color: #a6b9c1;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #546e7a;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #698998;
				}
				&:hover {
					border-bottom-color: #698998;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #009688;
			}
		}
	}
	.timeline {
		&:before {
			background: #616161;
		}
	}
	.timeline_icon {
		border-color: #616161;
	}
	.timeline_content_addon {
		background: #616161;
	}
	.k-panelbar {
		li.k-item {
			background: #363636;
		}
	}
	.chat_submit_box {
		background: #212121;
	}
	.scrum_board_overflow {
		background: #303030;
	}
	.scrum_task {
		background: #424242;
	}
	#scrum_board {
		.scrum_column {
			background: rgba(0,0,0,.25);
		}
		.scrum_column_heading {
			background: #303030;
		}
	}
	.blog_list_footer {
		border-top-color: rgba(0,0,0,.3);
	}
	#header_main {
		background: #546e7a;
		.uk-navbar {
			.uk-navbar-nav {
				>li.uk-open {
					>a {
						&:after {
							border-bottom-color: #424242;
						}
					}
				}
			}
		}
	}
	.header_double_height {
		&:after {
			background: #546e7a;
		}
	}
	#top_bar {
		background: #424242;
		.top_bar_nav {
			>li {
				>a {
					color: #fefefe;
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #546e7a;
						box-shadow: inset 0 -3px 0 #546e7a;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #546e7a;
					box-shadow: inset 0 -3px 0 #546e7a;
				}
			}
		}
	}
	.user_heading {
		background: #37474f;
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #009688;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #009688;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #009688;
							}
						}
						>ul {
							background: #424242;
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #009688;
								}
							}
						}
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #009688;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #009688;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #546e7a,inset 0 -1px 0 2px #546e7a;
			box-shadow: inset 0 0 0 2px #546e7a,inset 0 -1px 0 2px #546e7a;
		}
	}
	.dropify-wrapper {
		background: #424242;
		color: #fefefe;
		border-color: #616161;
		.dropify-preview {
			background: #424242;
			color: #fefefe;
		}
	}
	div.ganttview-vtheader {
		background: #424242;
		div.ganttview-vtheader-group-name {
			background: rgba(0,0,0,.5);
			border-top-color: rgba(0,0,0,.3);
		}
		div.ganttview-vtheader-group {
			color: #fefefe;
		}
		div.ganttview-vtheader-series-row {
			border-top-color: rgba(0,0,0,.3);
		}
	}
	div.ganttview-grid-spacer {
		border-top-color: rgba(0,0,0,.3);
		background: rgba(0,0,0,.5);
	}
	div.ganttview-grid-row-cell.ganttview-weekend {
		background: rgba(0,0,0,.35);
	}
	div.ganttview-hzheader-day.ganttview-weekend {
		background: rgba(0,0,0,.35);
	}
	div.ganttview-grid-row-cell {
		border-color: rgba(0,0,0,.3) !important;
	}
	div.ganttview-hzheader-day {
		border-color: rgba(0,0,0,.3) !important;
	}
	div.ganttview-hzheader-month {
		color: #fefefe;
	}
	.gmap-info-window {
		h3 {
			color: #212121;
		}
	}
	.gmap {
		.on_gmap {
			.controls_title {
				color: #727272;
			}
		}
	}
	.fc-toolbar {
		.fc-button {
			text-shadow: none;
		}
	}
	div.jtable-main-container {
		table.jtable {
			tbody {
				>tr.jtable-row-even {
					background: rgba(0,0,0,.2);
				}
				>tr {
					&:hover {
						background: rgba(0,0,0,.4);
					}
				}
				td {
					.jtable-command-button {
						background-color: rgba(255,255,255,.6);
						border-radius: 4px;
					}
				}
			}
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #cfd8dc;
				}
			}
		}
		.headerSortDown {
			color: #009688;
		}
		.headerSortUp {
			color: #009688;
		}
		.tablesorter-headerAsc {
			color: #009688;
		}
		.tablesorter-headerDesc {
			color: #009688;
		}
		.tablesorter-headerSortDown {
			color: #009688;
		}
		.tablesorter-headerSortUp {
			color: #009688;
		}
	}
	.wizard {
		>.steps {
			background: #546e7a;
			>ul {
				>li.current {
					a {
						background: #698998;
						&:active {
							background: #698998;
						}
						&:hover {
							background: #698998;
						}
					}
				}
				>li.done {
					a {
						background: #37474f;
						&:active {
							background: #37474f;
						}
						&:hover {
							background: #37474f;
						}
					}
				}
			}
		}
		>.actions {
			a {
				color: #fefefe;
				.material-icons {
					color: #fefefe;
				}
				&:active {
					color: #fefefe;
				}
				&:hover {
					color: #fefefe;
				}
			}
		}
	}
	.wizard.vertical {
		>.content {
			background: #424242;
		}
	}
	.md-card-toolbar[data-toolbar-progress] {
		.material-icons {
			color: #212121;
		}
		.md-card-toolbar-heading-text {
			color: #212121;
		}
	}
	.md-card-primary {
		border-left-color: #546e7a;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(84,110,122,.4);
		}
	}
	.search_list_link {
		color: #009688;
	}
	.selectize-dropdown {
		color: #fefefe;
		border-color: transparent;
		background: #616161;
		border-radius: 2px;
		.optgroup-header {
			color: #fefefe;
			background: #616161;
		}
	}
	.selectize-input {
		background: #616161;
		color: #fefefe;
		background: #616161;
	}
	.selectize-control.single {
		.selectize-input.input-active {
			background: #616161;
		}
		.selectize-input {
			&:after {
				color: #cbcbcb;
			}
		}
	}
	.selectize-control.multi {
		.selectize-input {
			>div {
				background: #424242;
				color: #fefefe;
			}
		}
	}
	.chatbox {
		background: #424242;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #546e7a;
		}
	}
	.chatbox_content {
		.chatbox_message {
			.chatbox_message_content {
				li {
					>span {
						background: rgba(0,0,0,.3);
					}
				}
			}
		}
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #546e7a;
					}
				}
			}
		}
	}
	.chatbox_footer {
		.message_input {
			background: 0 0;
			color: #fff;
		}
	}
}
.app_theme_e {
	.uk-alert {
		background: #607d8b;
	}
	.md-btn-primary {
		background: #607d8b;
		color: #fff;
		&:active {
			background: #607d8b;
			color: #fff;
		}
		&:focus {
			background: #607d8b;
			color: #fff;
		}
		&:hover {
			background: #607d8b;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #455a64;
		&:active {
			color: #455a64;
			background: #d5dee2;
		}
		&:focus {
			color: #455a64;
			background: #d5dee2;
		}
		&:hover {
			color: #455a64;
			background: #d5dee2;
		}
	}
	.uk-badge-primary {
		background: #607d8b;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #ffebee;
					}
				}
			}
			.md-card-list-item-selected {
				background: #ffebee;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #ffebee;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #e53935;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #e53935;
			}
		}
		>li.md-list-item-active {
			color: #e53935;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #e53935;
				.material-icons {
					color: #e53935;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #fceaea;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #e53935;
				color: #fff;
			}
			>span {
				background: #e53935;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #e53935;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #b7c6cd;
				}
				&:hover {
					border-bottom-color: #b7c6cd;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #607d8b;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #b7c6cd;
				}
				&:hover {
					border-top-color: #b7c6cd;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #607d8b;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #b7c6cd;
				}
				&:hover {
					border-right-color: #b7c6cd;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #607d8b;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #b7c6cd;
				}
				&:hover {
					border-left-color: #b7c6cd;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #607d8b;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #7b96a3;
				}
				&:hover {
					border-bottom-color: #7b96a3;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #e53935;
			}
		}
	}
	#header_main {
		background: #607d8b;
	}
	.header_double_height {
		&:after {
			background: #607d8b;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #607d8b;
						box-shadow: inset 0 -3px 0 #607d8b;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #607d8b;
					box-shadow: inset 0 -3px 0 #607d8b;
				}
			}
		}
	}
	.user_heading {
		background: #455a64;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #e53935;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #e53935;
							}
						}
						.menu_title {
							color: #e53935;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #e53935;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #e53935;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #e53935;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #e53935;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #e53935;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #e53935;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #e53935;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #607d8b,inset 0 -1px 0 2px #607d8b;
			box-shadow: inset 0 0 0 2px #607d8b,inset 0 -1px 0 2px #607d8b;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #ffebee;
				}
			}
		}
		.headerSortDown {
			color: #e53935;
		}
		.headerSortUp {
			color: #e53935;
		}
		.tablesorter-headerAsc {
			color: #e53935;
		}
		.tablesorter-headerDesc {
			color: #e53935;
		}
		.tablesorter-headerSortDown {
			color: #e53935;
		}
		.tablesorter-headerSortUp {
			color: #e53935;
		}
	}
	.wizard {
		>.steps {
			background: #607d8b;
			>ul {
				>li.current {
					a {
						background: #7b96a3;
						&:active {
							background: #7b96a3;
						}
						&:hover {
							background: #7b96a3;
						}
					}
				}
				>li.done {
					a {
						background: #455a64;
						&:active {
							background: #455a64;
						}
						&:hover {
							background: #455a64;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #607d8b;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(96,125,139,.4);
		}
	}
	.search_list_link {
		color: #e53935;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #455a64;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #607d8b;
					}
				}
			}
		}
	}
}
.app_theme_f {
	.uk-alert {
		background: #424242;
	}
	.md-btn-primary {
		background: #424242;
		color: #fff;
		&:active {
			background: #424242;
			color: #fff;
		}
		&:focus {
			background: #424242;
			color: #fff;
		}
		&:hover {
			background: #424242;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #212121;
		&:active {
			color: #212121;
			background: #cecece;
		}
		&:focus {
			color: #212121;
			background: #cecece;
		}
		&:hover {
			color: #212121;
			background: #cecece;
		}
	}
	.uk-badge-primary {
		background: #424242;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #f1f8e9;
					}
				}
			}
			.md-card-list-item-selected {
				background: #f1f8e9;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #f1f8e9;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #7cb342;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #7cb342;
			}
		}
		>li.md-list-item-active {
			color: #7cb342;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #7cb342;
				.material-icons {
					color: #7cb342;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #e1efd2;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #7cb342;
				color: #fff;
			}
			>span {
				background: #7cb342;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #7cb342;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #8f8f8f;
				}
				&:hover {
					border-bottom-color: #8f8f8f;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #424242;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #8f8f8f;
				}
				&:hover {
					border-top-color: #8f8f8f;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #424242;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #8f8f8f;
				}
				&:hover {
					border-right-color: #8f8f8f;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #424242;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #8f8f8f;
				}
				&:hover {
					border-left-color: #8f8f8f;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #424242;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #5c5c5c;
				}
				&:hover {
					border-bottom-color: #5c5c5c;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #7cb342;
			}
		}
	}
	#header_main {
		background: #424242;
	}
	.header_double_height {
		&:after {
			background: #424242;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #424242;
						box-shadow: inset 0 -3px 0 #424242;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #424242;
					box-shadow: inset 0 -3px 0 #424242;
				}
			}
		}
	}
	.user_heading {
		background: #212121;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #7cb342;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #7cb342;
							}
						}
						.menu_title {
							color: #7cb342;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #7cb342;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #7cb342;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #7cb342;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #7cb342;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #7cb342;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #7cb342;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #7cb342;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #424242,inset 0 -1px 0 2px #424242;
			box-shadow: inset 0 0 0 2px #424242,inset 0 -1px 0 2px #424242;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #f1f8e9;
				}
			}
		}
		.headerSortDown {
			color: #7cb342;
		}
		.headerSortUp {
			color: #7cb342;
		}
		.tablesorter-headerAsc {
			color: #7cb342;
		}
		.tablesorter-headerDesc {
			color: #7cb342;
		}
		.tablesorter-headerSortDown {
			color: #7cb342;
		}
		.tablesorter-headerSortUp {
			color: #7cb342;
		}
	}
	.wizard {
		>.steps {
			background: #424242;
			>ul {
				>li.current {
					a {
						background: #5c5c5c;
						&:active {
							background: #5c5c5c;
						}
						&:hover {
							background: #5c5c5c;
						}
					}
				}
				>li.done {
					a {
						background: #212121;
						&:active {
							background: #212121;
						}
						&:hover {
							background: #212121;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #424242;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(66,66,66,.4);
		}
	}
	.search_list_link {
		color: #7cb342;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #212121;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #424242;
					}
				}
			}
		}
	}
}
.app_theme_g {
	.uk-alert {
		background: #8e24aa;
	}
	.md-btn-primary {
		background: #8e24aa;
		color: #fff;
		&:active {
			background: #8e24aa;
			color: #fff;
		}
		&:focus {
			background: #8e24aa;
			color: #fff;
		}
		&:hover {
			background: #8e24aa;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #7b1fa2;
		&:active {
			color: #7b1fa2;
			background: #f0d7f6;
		}
		&:focus {
			color: #7b1fa2;
			background: #f0d7f6;
		}
		&:hover {
			color: #7b1fa2;
			background: #f0d7f6;
		}
	}
	.uk-badge-primary {
		background: #8e24aa;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #f3e5f5;
					}
				}
			}
			.md-card-list-item-selected {
				background: #f3e5f5;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #f3e5f5;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #4caf50;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #4caf50;
			}
		}
		>li.md-list-item-active {
			color: #4caf50;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #4caf50;
				.material-icons {
					color: #4caf50;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #d9eeda;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #4caf50;
				color: #fff;
			}
			>span {
				background: #4caf50;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #4caf50;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #d082e5;
				}
				&:hover {
					border-bottom-color: #d082e5;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #8e24aa;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #d082e5;
				}
				&:hover {
					border-top-color: #d082e5;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #8e24aa;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #d082e5;
				}
				&:hover {
					border-right-color: #d082e5;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #8e24aa;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #d082e5;
				}
				&:hover {
					border-left-color: #d082e5;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #8e24aa;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #b02ed3;
				}
				&:hover {
					border-bottom-color: #b02ed3;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #4caf50;
			}
		}
	}
	#header_main {
		background: #8e24aa;
	}
	.header_double_height {
		&:after {
			background: #8e24aa;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #8e24aa;
						box-shadow: inset 0 -3px 0 #8e24aa;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #8e24aa;
					box-shadow: inset 0 -3px 0 #8e24aa;
				}
			}
		}
	}
	.user_heading {
		background: #7b1fa2;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #4caf50;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #4caf50;
							}
						}
						.menu_title {
							color: #4caf50;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #4caf50;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #4caf50;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #4caf50;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #4caf50;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #4caf50;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #4caf50;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #4caf50;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #8e24aa,inset 0 -1px 0 2px #8e24aa;
			box-shadow: inset 0 0 0 2px #8e24aa,inset 0 -1px 0 2px #8e24aa;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #f3e5f5;
				}
			}
		}
		.headerSortDown {
			color: #4caf50;
		}
		.headerSortUp {
			color: #4caf50;
		}
		.tablesorter-headerAsc {
			color: #4caf50;
		}
		.tablesorter-headerDesc {
			color: #4caf50;
		}
		.tablesorter-headerSortDown {
			color: #4caf50;
		}
		.tablesorter-headerSortUp {
			color: #4caf50;
		}
	}
	.wizard {
		>.steps {
			background: #8e24aa;
			>ul {
				>li.current {
					a {
						background: #b02ed3;
						&:active {
							background: #b02ed3;
						}
						&:hover {
							background: #b02ed3;
						}
					}
				}
				>li.done {
					a {
						background: #7b1fa2;
						&:active {
							background: #7b1fa2;
						}
						&:hover {
							background: #7b1fa2;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #8e24aa;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(142,36,170,.4);
		}
	}
	.search_list_link {
		color: #4caf50;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #7b1fa2;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #8e24aa;
					}
				}
			}
		}
	}
}
.app_theme_h {
	.uk-alert {
		background: #d32f2f;
	}
	.md-btn-primary {
		background: #d32f2f;
		color: #fff;
		&:active {
			background: #d32f2f;
			color: #fff;
		}
		&:focus {
			background: #d32f2f;
			color: #fff;
		}
		&:hover {
			background: #d32f2f;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #c62828;
		&:active {
			color: #c62828;
			background: #fff;
		}
		&:focus {
			color: #c62828;
			background: #fff;
		}
		&:hover {
			color: #c62828;
			background: #fff;
		}
	}
	.uk-badge-primary {
		background: #d32f2f;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #ffebee;
					}
				}
			}
			.md-card-list-item-selected {
				background: #ffebee;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #ffebee;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #0277bd;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #0277bd;
			}
		}
		>li.md-list-item-active {
			color: #0277bd;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #0277bd;
				.material-icons {
					color: #0277bd;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #8dd4fe;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #0277bd;
				color: #fff;
			}
			>span {
				background: #0277bd;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #0277bd;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #eeadad;
				}
				&:hover {
					border-bottom-color: #eeadad;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #d32f2f;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #eeadad;
				}
				&:hover {
					border-top-color: #eeadad;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #d32f2f;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #eeadad;
				}
				&:hover {
					border-right-color: #eeadad;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #d32f2f;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #eeadad;
				}
				&:hover {
					border-left-color: #eeadad;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #d32f2f;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #dc5959;
				}
				&:hover {
					border-bottom-color: #dc5959;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #0277bd;
			}
		}
	}
	#header_main {
		background: #d32f2f;
	}
	.header_double_height {
		&:after {
			background: #d32f2f;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #d32f2f;
						box-shadow: inset 0 -3px 0 #d32f2f;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #d32f2f;
					box-shadow: inset 0 -3px 0 #d32f2f;
				}
			}
		}
	}
	.user_heading {
		background: #c62828;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #0277bd;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #0277bd;
							}
						}
						.menu_title {
							color: #0277bd;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #0277bd;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #0277bd;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #0277bd;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #0277bd;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #0277bd;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #0277bd;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #0277bd;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #d32f2f,inset 0 -1px 0 2px #d32f2f;
			box-shadow: inset 0 0 0 2px #d32f2f,inset 0 -1px 0 2px #d32f2f;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #ffebee;
				}
			}
		}
		.headerSortDown {
			color: #0277bd;
		}
		.headerSortUp {
			color: #0277bd;
		}
		.tablesorter-headerAsc {
			color: #0277bd;
		}
		.tablesorter-headerDesc {
			color: #0277bd;
		}
		.tablesorter-headerSortDown {
			color: #0277bd;
		}
		.tablesorter-headerSortUp {
			color: #0277bd;
		}
	}
	.wizard {
		>.steps {
			background: #d32f2f;
			>ul {
				>li.current {
					a {
						background: #dc5959;
						&:active {
							background: #dc5959;
						}
						&:hover {
							background: #dc5959;
						}
					}
				}
				>li.done {
					a {
						background: #c62828;
						&:active {
							background: #c62828;
						}
						&:hover {
							background: #c62828;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #d32f2f;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(211,47,47,.4);
		}
	}
	.search_list_link {
		color: #0277bd;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #c62828;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #d32f2f;
					}
				}
			}
		}
	}
}
.app_theme_i {
	.uk-alert {
		background: #fbc02d;
	}
	.md-btn-primary {
		background: #fbc02d;
		color: #fff;
		&:active {
			background: #fbc02d;
			color: #fff;
		}
		&:focus {
			background: #fbc02d;
			color: #fff;
		}
		&:hover {
			background: #fbc02d;
			color: #fff;
		}
	}
	.md-btn-flat-primary {
		color: #f57f17;
		&:active {
			color: #f57f17;
			background: #fff;
		}
		&:focus {
			color: #f57f17;
			background: #fff;
		}
		&:hover {
			color: #f57f17;
			background: #fff;
		}
	}
	.uk-badge-primary {
		background: #fbc02d;
	}
	.md-card-list-wrapper {
		.md-card-list {
			>ul {
				>li.item-shown.md-card-list-item-selected {
					&:before {
						background: #fffde7;
					}
				}
			}
			.md-card-list-item-selected {
				background: #fffde7;
			}
			.md-card-list-item-selected.item-shown {
				background: #fff;
				&:before {
					background: #fffde7;
				}
			}
		}
	}
	.md-fab.md-fab-accent {
		background: #00acc1;
	}
	.md-list {
		.uk-nestable-list {
			>li.md-list-item-active {
				color: #00acc1;
			}
		}
		>li.md-list-item-active {
			color: #00acc1;
		}
	}
	.md-list-addon {
		>li.md-list-item-active {
			.md-list-addon-element {
				color: #00acc1;
				.material-icons {
					color: #00acc1;
				}
			}
		}
	}
	.uk-pagination {
		>li {
			>a {
				&:hover {
					background: #8ef3ff;
				}
			}
		}
		>li.uk-active {
			>a {
				background: #00acc1;
				color: #fff;
			}
			>span {
				background: #00acc1;
				color: #fff;
			}
		}
	}
	.uk-subnav-pill {
		>.uk-active {
			>* {
				background: #00acc1;
			}
		}
	}
	.uk-tab {
		>li {
			>a {
				&:focus {
					border-bottom-color: #feedc3;
				}
				&:hover {
					border-bottom-color: #feedc3;
				}
			}
		}
		>li.uk-active {
			>a {
				border-bottom-color: #fbc02d;
			}
		}
	}
	.uk-tab-bottom {
		li {
			>a {
				&:focus {
					border-top-color: #feedc3;
				}
				&:hover {
					border-top-color: #feedc3;
				}
			}
		}
		li.uk-active {
			>a {
				border-top-color: #fbc02d;
			}
		}
	}
	.uk-tab-left {
		li {
			>a {
				&:focus {
					border-right-color: #feedc3;
				}
				&:hover {
					border-right-color: #feedc3;
				}
			}
		}
		li.uk-active {
			>a {
				border-right-color: #fbc02d;
			}
		}
	}
	.uk-tab-right {
		li {
			>a {
				&:focus {
					border-left-color: #feedc3;
				}
				&:hover {
					border-left-color: #feedc3;
				}
			}
		}
		li.uk-active {
			>a {
				border-left-color: #fbc02d;
			}
		}
	}
	.uk-tab-double-header {
		li {
			a {
				&:focus {
					border-bottom-color: #fccf5f;
				}
				&:hover {
					border-bottom-color: #fccf5f;
				}
			}
		}
		li.uk-active {
			>a {
				border-bottom-color: #00acc1;
			}
		}
	}
	#header_main {
		background: #fbc02d;
	}
	.header_double_height {
		&:after {
			background: #fbc02d;
		}
	}
	#top_bar {
		.top_bar_nav {
			>li {
				>a {
					&:hover {
						-webkit-box-shadow: inset 0 -3px 0 #fbc02d;
						box-shadow: inset 0 -3px 0 #fbc02d;
					}
				}
			}
			>li.uk-active {
				a {
					-webkit-box-shadow: inset 0 -3px 0 #fbc02d;
					box-shadow: inset 0 -3px 0 #fbc02d;
				}
			}
		}
	}
	.user_heading {
		background: #f57f17;
	}
	#sidebar_main {
		.menu_section {
			>ul {
				>li {
					ul {
						li.act_item {
							a {
								color: #00acc1;
							}
						}
					}
				}
				>li.current_section {
					>a {
						>.menu_icon {
							.material-icons {
								color: #00acc1;
							}
						}
						.menu_title {
							color: #00acc1;
						}
					}
				}
			}
		}
	}
	.sidebar_mini {
		#sidebar_main {
			.menu_section {
				>ul {
					>li {
						>a {
							>.menu_title {
								background: #00acc1;
								color: #fff;
							}
						}
						&:hover.sidebar_submenu {
							background: #00acc1;
							>a {
								.menu_icon {
									.material-icons {
										color: #fff;
									}
								}
							}
							ul {
								border-left-color: #00acc1;
							}
						}
					}
					>li.current_section {
						>a {
							>.menu_icon {
								.material-icons {
									color: #00acc1;
								}
							}
						}
					}
				}
			}
		}
	}
	.clndr {
		.clndr_days {
			.clndr_days_grid {
				.day.today {
					>span {
						background: #00acc1;
					}
				}
			}
		}
	}
	.DTTT_print_info {
		h6 {
			color: #00acc1;
		}
	}
	.fc-unthemed {
		.fc-button.fc-state-active {
			&:after {
				color: #00acc1;
			}
		}
		.fc-highlight {
			-webkit-box-shadow: inset 0 0 0 2px #fbc02d,inset 0 -1px 0 2px #fbc02d;
			box-shadow: inset 0 0 0 2px #fbc02d,inset 0 -1px 0 2px #fbc02d;
		}
	}
	.tablesorter-altair {
		tbody {
			>tr.row_highlighted {
				>td {
					background: #fffde7;
				}
			}
		}
		.headerSortDown {
			color: #00acc1;
		}
		.headerSortUp {
			color: #00acc1;
		}
		.tablesorter-headerAsc {
			color: #00acc1;
		}
		.tablesorter-headerDesc {
			color: #00acc1;
		}
		.tablesorter-headerSortDown {
			color: #00acc1;
		}
		.tablesorter-headerSortUp {
			color: #00acc1;
		}
	}
	.wizard {
		>.steps {
			background: #fbc02d;
			>ul {
				>li.current {
					a {
						background: #fccf5f;
						&:active {
							background: #fccf5f;
						}
						&:hover {
							background: #fccf5f;
						}
					}
				}
				>li.done {
					a {
						background: #f57f17;
						&:active {
							background: #f57f17;
						}
						&:hover {
							background: #f57f17;
						}
					}
				}
			}
		}
	}
	.md-card-primary {
		border-left-color: #fbc02d;
	}
	.waves-effect.md-btn-flat-primary {
		background: 0 0;
		.waves-ripple {
			background: rgba(251,192,45,.4);
		}
	}
	.search_list_link {
		color: #00acc1;
	}
	.chatbox.cb_active {
		.chatbox_header {
			background: #f57f17;
		}
	}
	.chatbox_content {
		.chatbox_message.own {
			.chatbox_message_content {
				li {
					>span {
						background: #fbc02d;
					}
				}
			}
		}
	}
}
@media only screen and (-webkit-min-device-pixel-ratio:2) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
@media only screen and (min--moz-device-pixel-ratio:2) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
@media only screen and (min-device-pixel-ratio:2) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
@media only screen and (-webkit-min-device-pixel-ratio:192) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
@media only screen and (min-resolution:192dppx) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
@media only screen and (min-resolution:2dppx) {
	.app_theme_dark {
		#sidebar_main {
			.sidebar_main_header {
				background-image: url(../../images/themes/altair/sidebar_head_bg_dark@2x.png);
				background-size: 240px 88px;
			}
		}
	}
}
